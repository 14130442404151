(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name altmodRegisterController
* @description
* This is main controller
**/ 

    angular
    .module('altmod.register')
    .controller('altmodRegisterController', altmodRegisterController);

    altmodRegisterController.$inject = ['altmodRegisterService',"$window","$timeout"];

    function altmodRegisterController(altmodRegisterService,$window,$timeout)
    {
    	console.log("register is enabled ");
    	var vm = this;
        vm.reg = altmodRegisterService;
        vm.user = {};
        vm.loginError = false;


        vm.codigos_postales = ['08911','08912','08913','08914','08915','08916','08917','08918','08214','08210','08001','08002','08003','08004','08005','08006','08007','08008','08009','08010','08011','08012','08013','08014','08015','08016','08017','08018','08019','08020','08021','08022','08023','08024','08025','08026','08027','08028','08029','08030','08031','08032','08033','08034','08035','08036','08037','08038','08039','08040','08041','08042','08750','08859','08755','08860','08193','08290','08758','08757','08940','08754','08820','08950','08850','08901','08902','08903','08904','08905','08906','08907','08908','08756','08750','08110','08390','08753','08780','08291','08930','08740','08830','08849','08172','08173','08174','08195','08196','08197','08198','08980','08970','08960','08620','08690','08921','08922','08923','08924','08390','08391','08916','08629','08840'];

        vm.comprobarPostalCode = function(){
            var res = false;
            if(angular.isDefined(vm.user) && vm.codigos_postales.indexOf(vm.user.postal_code) !== -1) {
                res = true;
            }
            return res;
        }

        vm.registro = function(form)
        {
            console.log("registro function");
        	console.log("registro function",form.$valid);
            if(!form.$valid || !vm.comprobarPostalCode())
                return false;

            console.log("register user");

            return vm.reg.registro(vm.user).then(function(data)
            {
                console.log(data);
                if(data.code == 200){
                    
                    $window.location.reload();
                }else{
                    vm.loginError = true;
                    $timeout(function()
                    {
                        vm.loginError = false;
                    },5000);
                }

            });
        };

    }


})();